@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/text/gilroy/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
