@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "gilroy", sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.empty-data-error-message {
  color: grey;
  text-align: center;
}

.general-component-wrapper {
  @media (min-width: 1000px) {
    max-width: 750px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
