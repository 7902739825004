.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
  /* border-top: 10px solid var(--Gradients-Blue-Red, #087af9);
  border-bottom: 10px solid var(--Gradients-Blue-Red, #087af9); */
  background: linear-gradient(
    45.7deg,
    #3573ff 2.78%,
    #ff3fdb 57.6%,
    #ff1022 114.72%
  );
  animation: loading 2s linear infinite;
}
.loader img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background-color: red; */
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
