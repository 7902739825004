.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 400px;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.rectangle {
  width: 100%;
  height: 100px;
  border-radius: 10px;
}

.square {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}
